<template>
  <div class="box" @click="toHome">
    <img class="img" src="../../src/assets/image/topBg.png" alt="" />
    <img class="back" src="../assets/gxImg/home/back.png" alt="" />
    <h2>2024年中国（太原）国际能源产业博览会</h2>
    <div class="menu">
      <span
        v-for="(item, index) in title"
        @click.stop="onClick(item, index)"
        :style="current == index ? 'color:#fff;font-weight: bold;' : ''"
      >
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { homeColumn } from "../api/index";
export default {
  props: ["index"],
  data() {
    return {
      title: [],
      current: "",
    };
  },
  mounted() {
    this.getList();
    this.current = this.index;
    // console.log(this.index, 969696);
  },
  watch: {
    index(news, old) {
      console.log(news, old);
      // this.current = news;
    },
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    // 栏目列表
    getList() {
      homeColumn().then((res) => {
        this.title = res.rows;
        // console.log("xxx", this.title);
      });
    },

    onClick(v, i) {
      this.current = i;
      // console.log("v,i", v, i);
      if (i == 0) {
        this.$router.push(`/situation?section=${v.id}`);
      }

      if (i == 1) {
        this.$router.push(`/exhibitor?section=${v.id}`);
      }

      if (i == 2) {
        this.$router.push(`/newsList?section=${v.id}`);
      }
      if (i == 3) {
        this.$router.push(`/active?section=${v.id}`);
      }
      if (i == 4) {
        this.$router.push(`/Handbook?section=${v.id}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 120px;
  background: #0c1673 !important;
  color: #fff;
  position: relative;
  z-index: 99999;
  .img {
    position: absolute;
    height: 100%;
    width: 35%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .back {
    position: absolute;
    height: 2.5vw;
    width: 2.5vw;
    left: 1.5625vw;
    top: 2.78vh;
    z-index: 1;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 80px;
    font-weight: bold;
    z-index: 9;
  }
  .menu {
    width: 70%;
    line-height: 30px;
    margin: auto;
    z-index: 99;
    position: relative;
    display: flex;
    justify-content: space-around;
    span {
      color: #1b76e8;
      font-size: 16px;
      cursor: pointer;
    }
    span:hover {
      color: #fff;
      font-weight: bold;
    }
    span:active {
      opacity: 0.8;
      font-weight: bold;
    }
  }
}
</style>

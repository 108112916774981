<template>
  <div class="box">
    <topMenu :index="3"></topMenu>
    <div class="page-content">
      <img class="wen1" src="../../src/assets/image/wen1.png" alt="" />
      <img class="wen2" src="../../src/assets/image/wen1.png" alt="" />
      <div style="position: relative">
        <div class="con_title">
          <span>重大活动</span>
          <img src="../../src/assets/image/title_right.png" alt="" />
        </div>
        <div class="con_item great-activity-item" v-for="item in importList">
          <div class="item_time">{{ item.actiTime }}</div>
          <ul>
            <li>
              <div class="label">活动名称：</div>
              <div class="content">{{ item.title }}</div>
            </li>
            <li>
              <div class="label">活动地点：</div>
              <div class="content">{{ item.actiPlace }}</div>
            </li>
          </ul>
        </div>
        <div class="con_title">
          <span>专题活动</span>
          <img src="../../src/assets/image/title_right.png" alt="" />
        </div>

        <div class="con_item green special-activity-item">
          <div class="item_time">2024.9.10-9.11</div>
          <ul>
            <li>
              <div class="label">活动名称：</div>
              <div class="content">首发活动</div>
            </li>
            <li>
              <div class="label">活动地点：</div>
              <div class="content">山西潇河国际会展中心N1馆-首发区域</div>
            </li>
          </ul>
        </div>
        <div class="con_item green static-activity-item" v-for="(item, index) in staticActivityList" :key="index">
          <div class="item_time">{{ item.time }}</div>
          <ul>
            <li>
              <div class="label">发布单位：</div>
              <div class="content">{{ item.employer }}</div>
            </li>
            <li>
              <div class="label">发布产品名称：</div>
              <div class="content">
                <div v-for="(nameItem, nameIndex) in item.name" :key="nameIndex" class="content-item">
                  {{ nameItem }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="con_item green special-activity-item" v-for="item in projectLIst">
          <div class="item_time">{{ item.actiTime }}</div>
          <ul>
            <li>
              <div class="label">活动名称：</div>
              <div class="content">{{ item.title }}</div>
            </li>
            <li>
              <div class="label">活动地点：</div>
              <div class="content">{{ item.actiPlace }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import topMenu from '@/components/topMenu.vue'
import Footer from '../components/Footer.vue'
import { timeList } from '../api/index.js'
export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      importList: [],
      projectLIst: [],

      staticActivityList: [
        {
          time: '9月10日上午 10:30-10:50',
          employer: '山西国润储能科技有限公司',
          name: ['1.全钒液流电池储能系统', '2.GR-IEM-11N全氟磺酸离子交换膜'],
          modality: '讲解+PPT和宣传片',
          duration: 20,
          contacts: [
            {
              name: '焦赟',
              phone: '15003499007',
            },
          ],
        },
        {
          time: '9月10日上午 10:50-11:05',
          employer: '中车大同电力机车有限公司',
          name: ['电气化公路'],
          modality: '',
          duration: 15,
          contacts: [
            {
              name: '王欣宇',
              phone: '18935219209',
            },
          ],
        },
        {
          time: '9月10日上午 11:05-11:35',
          employer: '山西高科华杰光电科技股份有限公司',
          name: ['1.太岳系列户外固装产品', '2.动态像素产品'],
          modality: '',
          duration: 30,
          contacts: [
            {
              name: '王锐',
              phone: '17636310666',
            },
          ],
        },
        {
          time: '9月10日下午 14:30-14:50',
          employer: '多氟多阳福新材料有限公司',
          name: ['六氟磷酸锂'],
          modality: '讲解+PPT或宣传片',
          duration: 20,
          contacts: [
            {
              name: '王振鑫',
              phone: '15635303530',
            },
          ],
        },
        {
          time: '9月10日下午 14:50-15:10',
          employer: '华阳集团',
          name: ['华阳集团钠离子电池系列产品'],
          modality: '讲解+PPT或宣传片',
          duration: 20,
          contacts: [
            {
              name: '张卡',
              phone: '18735341963',
            },
            {
              name: '陈文强',
              phone: '18003539988',
            },
          ],
        },
        {
          time: '9月10日下午 15:10-15:25',
          employer: '山西焦煤集团',
          name: ['大宗煤基固废利用与采注协同绿色开采技术'],
          modality: '讲解+PPT或宣传片',
          duration: 15,
          contacts: [
            {
              name: '伍华莹',
              phone: '13466835181',
            },
          ],
        },
        {
          time: '9月10日下午 15:25-15:45',
          employer: '潞安化工集团',
          name: ['晋华炉3.0'],
          modality: '讲解+PPT或宣传片',
          duration: 20,
          contacts: [
            {
              name: '孙逸',
              phone: '13503514426',
            },
          ],
        },
        {
          time: '9月10日下午 15:45-16:05',
          employer: '太重集团',
          name: ['太重新能源挖掘机首发推介'],
          modality: '讲解+PPT或宣传片',
          duration: 20,
          contacts: [
            {
              name: '李晗',
              phone: '15333016508',
            },
          ],
        },
        {
          time: '9月11日上午 10:00-10:10',
          employer: '高速飞车山西省实验室',
          name: ['高速飞车项目'],
          modality: '',
          duration: 10,
          contacts: [
            {
              name: '姚书龙',
              phone: '15210320673',
            },
          ],
        },
        {
          time: '9月11日上午 10:10-11:10',
          employer: '山西省环境气象预报评估技术创新中心',
          name: ['新三可体系下区域碳源汇评估产品'],
          modality: '讲解+PPT',
          duration: 60,
          contacts: [
            {
              name: '杨东',
              phone: '15203512889',
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.gettimeList()
  },
  methods: {
    gettimeList() {
      timeList().then(res => {
        let list = (res.rows || []).map(item => {
          return {
            ...item,
            actiTime: item.id === 8 ? '2024-9-10--12日（每日三场）' : item.actiTime,
          }
        })
        this.importList = []
        this.projectLIst = []
        list.map(i => {
          if (i.actiType == 1) {
            this.importList.push(i)
          } else if (i.actiType == 2) {
            this.projectLIst.push(i)
          }
        })
        console.log(this.importList, 7878)
        console.log(this.projectLIst, 888)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page-content {
  width: 100%;
  position: relative;
  margin: 20px auto 50px;
  .wen1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 530px;
    height: 408px;
  }
  .wen2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 610px;
    height: 407px;
  }
}

.con_title {
  width: 55%;
  margin: 40px auto 20px;
  display: flex;
  span {
    font-size: 24px;
    line-height: 45px;
    text-align: center;
  }

  img {
    margin: 10px;
    width: 26px;
    height: 24.5px;
  }
}

.con_item {
  width: 55%;
  box-sizing: border-box;
  background: #f7eccb;
  display: flex;
  align-items: center;
  color: #e19c3a;
  padding: 10px;
  font-size: 18px;
  margin: 10px auto;

  .item_time {
    width: 45%;
    height: 60px;
    border-right: #ffffff 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > ul {
    width: 65%;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 0 8px 20px;

    > li {
      display: flex;
      margin-bottom: 12px;

      .label {
        flex-shrink: 0;
      }

      .content-item {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.static-activity-item  {
    .label {
      min-width: 126px;
    }
  }
}
.green {
  background: #e0f3ec;
  color: #14944b;
}
</style>
